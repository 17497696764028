.deer {
    width: 2em;
    height: 2em;
    display: inline-block;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
} 

.one {
    background: center / contain no-repeat url('../../images/deer_1.png');
}

.two {
    background: center / contain no-repeat url('../../images/deer_2.png');
}

.three {
    background: center / contain no-repeat url('../../images/deer_3.png');
}

.four {
    background: center / contain no-repeat url('../../images/deer_4.png');
}

.five {
    background: center / contain no-repeat url('../../images/deer_5.png');
}

.network-status {
    text-align: center;
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1em 0;
    background-color: red;
}

.network-status p {
    font-size: 1.5em;
}

.switch-to-mainnet {
    margin: 0.5em;
    font-size: 1.2em;
    padding: 0.5em 1em;
}

.minting-limits {
    margin-top: 1em;
    font-size: 1.2em;
    text-align: center;
}

.limit {
    color: var(--blue);
}

$border-width: 0.2em;

$con-text-color: #fff;

$con-button-background: #F6851B;
$con-shadow-color: #CD6116;
$con-highlight-color: #FEEEDF;

$con-hov-button-background: #F63C1B;
$con-hov-shadow-color: #CD2416;
$con-hov-hightlight-color: #FEE4DF;

$con-act-button-background: #F63C1B;
$con-act-shadow-color: #CD2416;
$con-act-higlight-color: #FEE4DF;

.big-button {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 20em;
    height: 3em;
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 1.5em 1.5em;
    font-family: 'Press Start 2P';
    text-decoration: none;
    text-transform: uppercase;
    margin: auto;
    box-sizing: border-box;
    cursor: pointer;
}

@media(max-width: 720px) {
    .big-button {
        width: 18em;
        font-size: 1.7em;
    }

    .status {
        display: inline;
        width: 18em;
        text-align: center;
        font-size: 1em;
        margin-bottom: 1.5em;
        text-align: center;
    }
}

@media(max-width: 530px) {
    .big-button {
        width: 20em;
        font-size: 1.2rem;
    }

    .deer {
        width: 1.5em;
        height: 1.5em;
    }
}

@media(max-width: 400px) {
    .big-button {
        width: 12em;
    }

    .deer {
        width: 1.2em;
        height: 1.2em;
    }
}

.connect-button {
    background: $con-button-background;
    color: $con-text-color;
    border-top: $con-highlight-color $border-width solid;
    border-left: $con-highlight-color $border-width solid;
    border-bottom: $con-shadow-color $border-width solid;
    border-right: $con-shadow-color $border-width solid;

    &:hover, &:focus {
        background-color: $con-hov-button-background;
        border-top: $con-hov-hightlight-color $border-width solid;
        border-left: $con-hov-hightlight-color $border-width solid;
        border-bottom: $con-hov-shadow-color $border-width solid;
        border-right: $con-hov-shadow-color $border-width solid;
    }

    &:active {
        background-color: $con-act-button-background;
        border-top: $con-act-higlight-color ($border-width/1.7) solid;
        border-left: $con-act-higlight-color ($border-width/1.7) solid;
        border-bottom: $con-act-shadow-color ($border-width/1.7) solid;
        border-right: $con-act-shadow-color ($border-width/1.7) solid;
    }
}

$mint-text-color: #045B17;
$mint-hov-text-color: #046044;

$mint-button-background: #1BF64B;
$mint-shadow-color: #16CD30;
$mint-highlight-color: #DFFEE6;

$mint-hov-button-background: #1BF6B4;
$mint-hov-shadow-color: #16CD89;
$mint-hov-hightlight-color: #DFFEF5;

$mint-act-button-background: #1BF6B4;
$mint-act-shadow-color: #16CD89;
$mint-act-higlight-color: #DFFEF5;

.mint-button {
    background: $mint-button-background;
    color: $mint-text-color;
    border-top: $mint-highlight-color $border-width solid;
    border-left: $mint-highlight-color $border-width solid;
    border-bottom: $mint-shadow-color $border-width solid;
    border-right: $mint-shadow-color $border-width solid;

    &:hover, &:focus {
        color: $mint-hov-text-color;
        background-color: $mint-hov-button-background;
        border-top: $mint-hov-hightlight-color $border-width solid;
        border-left: $mint-hov-hightlight-color $border-width solid;
        border-bottom: $mint-hov-shadow-color $border-width solid;
        border-right: $mint-hov-shadow-color $border-width solid;
    }

    &:active {
        color: $mint-hov-text-color;
        background-color: $mint-act-button-background;
        border-top: $mint-act-higlight-color ($border-width/1.7) solid;
        border-left: $mint-act-higlight-color ($border-width/1.7) solid;
        border-bottom: $mint-act-shadow-color ($border-width/1.7) solid;
        border-right: $mint-act-shadow-color ($border-width/1.7) solid;
    }
}


$diamond-mint-text-color: #042A60;
$diamond-mint-hov-text-color: #044460;

$diamond-mint-button-background: #1B76F6;
$diamond-mint-shadow-color: #135EAD;
$diamond-mint-highlight-color: #DFECFE;

$diamond-mint-hov-button-background: #1BB4F6;
$diamond-mint-hov-shadow-color: #16A4CD;
$diamond-mint-hov-hightlight-color: #DFF5FE;

$diamond-mint-act-button-background: #1BB4F6;
$diamond-mint-act-shadow-color: #16A4CD;
$diamond-mint-act-higlight-color: #DFF5FE;

.diamond-mint-button {
    background: $diamond-mint-button-background;
    color: $diamond-mint-text-color;
    box-sizing: border-box;
    border-top: $diamond-mint-highlight-color $border-width solid;
    border-left: $diamond-mint-highlight-color $border-width solid;
    border-bottom: $diamond-mint-shadow-color $border-width solid;
    border-right: $diamond-mint-shadow-color $border-width solid;

    &:hover, &:focus {
        color: $diamond-mint-hov-text-color;
        background-color: $diamond-mint-hov-button-background;
        border-top: $diamond-mint-hov-hightlight-color $border-width solid;
        border-left: $diamond-mint-hov-hightlight-color $border-width solid;
        border-bottom: $diamond-mint-hov-shadow-color $border-width solid;
        border-right: $diamond-mint-hov-shadow-color $border-width solid;
    }

    &:active {
        color: $diamond-mint-hov-text-color;
        background-color: $diamond-mint-act-button-background;
        border-top: $diamond-mint-act-higlight-color ($border-width/1.7) solid;
        border-left: $diamond-mint-act-higlight-color ($border-width/1.7) solid;
        border-bottom: $diamond-mint-act-shadow-color ($border-width/1.7) solid;
        border-right: $diamond-mint-act-shadow-color ($border-width/1.7) solid;
    }
}

$disabled-mint-text-color: #352F2E;
$disabled-mint-hov-text-color: #352F2E;

$disabled-mint-button-background: #918180;
$disabled-mint-shadow-color: #727272;
$disabled-mint-highlight-color: #F0EDED;

$disabled-mint-hov-button-background: #918180;
$disabled-mint-hov-shadow-color: #727272;
$disabled-mint-hov-hightlight-color: #F0EDED;

$disabled-mint-act-button-background: #918180;
$disabled-mint-act-shadow-color: #727272;
$disabled-mint-act-higlight-color: #F0EDED;

.mint-button-disabled {
    background: $disabled-mint-button-background;
    color: $disabled-mint-text-color;
    box-sizing: border-box;
    border-top: $disabled-mint-highlight-color $border-width solid;
    border-left: $disabled-mint-highlight-color $border-width solid;
    border-bottom: $disabled-mint-shadow-color $border-width solid;
    border-right: $disabled-mint-shadow-color $border-width solid;

    &:hover, &:focus {
        color: $disabled-mint-hov-text-color;
        background-color: $disabled-mint-hov-button-background;
        border-top: $disabled-mint-hov-hightlight-color $border-width solid;
        border-left: $disabled-mint-hov-hightlight-color $border-width solid;
        border-bottom: $disabled-mint-hov-shadow-color $border-width solid;
        border-right: $disabled-mint-hov-shadow-color $border-width solid;
    }

    &:active {
        color: $disabled-mint-hov-text-color;
        background-color: $disabled-mint-act-button-background;
        border-top: $disabled-mint-act-higlight-color ($border-width) solid;
        border-left: $disabled-mint-act-higlight-color ($border-width) solid;
        border-bottom: $disabled-mint-act-shadow-color ($border-width) solid;
        border-right: $disabled-mint-act-shadow-color ($border-width) solid;
    }
}

.amount {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: 'Press Start 2P';
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    margin: auto;
    margin-bottom: 1.2em;
}

.count {
    margin-left: 2em;
    margin-right: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count-button {
    background: rgba(255,255,255,.1); 
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    font-size: 1.1em;
    width: 2em;
    height: 2em;
    cursor: pointer;
    &:hover, &:focus {
        background: rgba(255,255,255,.15);
    }

    &:active {
        background: rgba(255,255,255,.2);
        color: var(--yellow);
    }
}

.status {
    font-size: 1.2em;
    display: block;
    margin: auto;
    margin-bottom: 1.5em;
    text-align: center;
}

.wallet {
    color: var(--blue);
}

.whitelisted {
    color: var(--green);
}

.not-whitelisted {
    color: var(--red);
}

.transaction-status {
    margin-top: 1.5em;
}
.transaction-status p {
    font-size: 1em;

    a, a:visited {
        color: var(--dark_blue);
        text-decoration: none;
      }
    
      a:hover {
        color: var(--red);
      }
}

.list-of-deers {
    margin-top: 2em;
    h1 {
        font-size: 2em;
        text-align: center;
    }

    p {
        font-size: 3em;
    }
}

p {
    text-align: center;
}

.no-metamask a, .no-metamask a:visited {
    color: var(--dark_blue);
    text-decoration: none;
}

.no-metamask a:hover {
    color: var(--red);
    text-decoration: underline;
}