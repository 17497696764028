.about {
  width: 100%;
}

.about h1 {
  margin-top: 1em;
}

.features {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 3em;
}

.features .feature-list {
  padding: 0 4em;
  margin-bottom: 2em;
}

.features .feature-list ul li {
  font-size: calc(.5rem + 1vw);
  margin-bottom: 1.2em;
  list-style-type: none;
  line-height: 1.7;
}

.features .preview {
  align-self: flex-start;
}

.features .preview img {
  display: block;
  text-align: center;
  width: calc(360px + 4vw);
  margin: 0 auto;
  border-radius: 50%;
}

@media (max-width: 1000px) {
  .features {
    flex-direction: column;
  }

  .features .feature-list {
    padding: 0 .5em;
  }

  .features .feature-list li {
    font-size: calc(.5rem + 1vw);
    margin-bottom: 0.8em;
    list-style-type: none;
  }

  .features .preview {
    align-self: initial;
  }

  .features .preview img {
    margin-bottom: 4em;
    max-width: 360px;
  }
}

@media (max-width: 440px) {
  .features .feature-list {
    padding: 0;
  }
  .features .preview {
    align-self: initial;
  }
}

@media (max-width: 360px) {
  .features .preview img {
    width: 100%;
  }

  .features .feature-list {
    padding: 0;
  }

  .features .feature-list ul li {
    font-size: .8em;
  }
}