.team {
    width: 100%;
    margin-bottom: 4em;
}
.team-members {
    padding: 0 22m;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.member {
    box-sizing: border-box;
    min-width: 22em;
    padding: 3em 2em;
    margin: 1em;
    flex: 0 1 30%;
    text-align: center;
    background-color: #080808;
    border-radius: .75em;
    border: 1px solid #222;
}

.member-pfp {
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid rgba(255,255,255,.1);
}

.member-name {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: calc(1rem + 1vw);
    font-weight: bold;
}

// .member-info {}

.member-twitter img {
    width: 1.2em;
    height: 1.2em;
    align-self: center;
    margin-right: .5em;
}

.member-twitter {
    align-self: center;
    font-weight: bold;
    font-size: 1.1em;
    color: rgba(29,155,240,1);
    margin-top: 2em;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.member-twitter a {
    color: rgba(29,155,240,1);
    text-decoration: none;
}

.member-twitter a:hover {
    color: var(--yellow); 
}

@media (max-width: 1280px) {
    .member {
        min-width: 20em;
    }
}

@media (max-width: 1120px) {
    .member {
        min-width: 18em;
    }
}

@media (max-width: 420px) {
    .member {
        min-width: 18em;
    }
}

@media (max-width: 320px) {
    .member {
        min-width: 14em;
    }
}