.hero {
  width: 100%;
  height: 288px;
  padding: 0;
  align-items: center;
  background: repeat url('../../images/header_bg.png');
  animation: bg_header 90s linear infinite;
}

@keyframes bg_header {
  from {background-position: 1152px 0;}
  to {background-position: 0 0;}
}

.logo {
  background: center / contain no-repeat url('../../images/deer_logo.png') ;
  margin: 2em auto 0;
  border: 8px #000 solid;
  box-sizing: border-box;
  border-radius: 50%;
  width: 128px;
  height: 128px;
}

.hero h1 {
  width: 6.9em;
  font-size: 2.6rem;
  font-family: 'Manrope';
  text-align: center;
  margin: 1em auto;
  background: black;
}

.tagline {
  margin-top: 1em;
  font-size: calc(.75em + 1vw);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: .75em;
}