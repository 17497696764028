@font-face {
  font-family: 'Terminal Grotesque Open';
  src: url('./fonts/terminal-grotesque_open.otf') format('opentype');
}

:root {
  font-size: calc(1em + 0.05vw);
}

body {
  margin: 0;
  background-color: var(--bg);
  color: var(--text);
  font-family: 'Roboto Mono';
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1, h2 {
  text-align: center;
  font-family: 'Terminal Grotesque Open';
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2.5em + 2vw);
  line-height: 1.2em;
}

h2 {
  font-family: 'Roboto Mono';
  font-weight: bold;
  font-size: calc(1em + 1vw);
}

p, ul {
  margin: 0;
  padding: 0;
}

.termOpen {
  font-family: 'Terminal Grotesque Open';
}

.uppercase {
  text-transform: uppercase;
}

.blue {
  color: var(--blue);
}

.darkBlue {
  color: var(--dark_blue);
}

.green {
  color: var(--green);
}

.lime {
  color: var(--lime);
}

.pink {
  color: var(--pink);
}

.red {
  color: var(--red);
}

.violet {
  color: var(--violet);
}

.yellow {
  color: var(--yellow);
}

:root {
  --bg: #000;
  --text: #fff;
  --blue: #66FFFF;
  --dark_blue: #668CFF;
  --green: #66FF8C;
  --lime: #B2FF66;
  --pink: #FF66D7;
  --red: #FF6666;
  --violet: #B266FF;
  --yellow: #FFD766;
}