.links {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.link-list {
  background-color: rgba(0,0,0,.95);
  border-radius: 2em;
  padding: 0 2em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.link-list img {
  border-radius: 50%;
  transition-duration: .1s;
  padding: 1em;
  margin: 1em;
  width: 6em;
  height: 6em;
}

.link-list .discord:hover {
  background-color: rgba(88, 101, 242, .1);
  box-shadow: 0px 0px 0px 4px rgba(88, 101, 242, 1);
}

.link-list .twitter:hover {
  background-color: rgba(29, 155, 240, .1);
  box-shadow: 0px 0px 0px 4px rgba(29, 155, 240, 1);
}

.link-list .looksrare:hover {
  background-color: rgba(12,228,102,.1);
  box-shadow: 0px 0px 0px 4px rgba(12,228,102,1);
}

.link-list .opensea:hover {
  background-color: rgba(32, 129, 226, .1);
  box-shadow: 0px 0px 0px 4px rgba(32, 129, 226, 1);
}

.link-list .etherscan:hover {
  background-color: rgba(191, 207, 218, .1);
  box-shadow: 0px 0px 0px 4px rgba(197, 207, 218, 1);
}

.link-button img {
  width: 5em;
  height: 5em;
}

@media (max-width: 860px) {
  .links {
    height: 300px;
  }
  .link-list {
    padding: 0 1.5em;
  }
  .link-list img {
    width: 4em;
    height: 4em;
  }
}

@media (max-width: 720px) {
  .link-list {
    padding: 0 0.5em;
  }
}

@media (max-width: 640px) {
  .link-list {
    padding: 0 0.5em;
  }
  .link-list img {
    width: 3em;
    height: 3em;
  }
}

@media (max-width: 520px) {
  .links {
    height: 420px;
  }
  .link-list {
    margin: 0 4em;
  }
}

@media (max-width: 420px) {
  .links {
    height: 400px;
  }
  .link-list img {
    margin: .5em;
  }
}

@media (max-width: 380px) {
  .links {
    height: 580px;
  }
  .link-list {
    flex-direction: column;
  }
}