.mintStatus {
  h1.total-minted {
    font-size: 3em;
    margin-bottom: .1em;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
  }

  h2 {
    color: green;
  }

  .fetching {
    color: var(--yellow);
    text-align: center;
  }

  .open {
    color: var(--green);
  }

  a, a:visited {
    color: var(--dark_blue);
    text-decoration: none;
  }

  a:hover {
    color: var(--red);
    text-decoration: underline;
  }
  
  .date {
    color: var(--blue);
  }

  margin-bottom: 4.5em;

}