.footer {
    width: 100%;
    height: 576px;
    padding: 0;
    align-items: center;
    background: repeat url('../../images/header_bg_x2.png');
    animation: bg_footer 120s linear infinite;
}
  
@keyframes bg_footer {
    from {background-position: -2304px 0;}
    to {background-position: 0 0;}
}