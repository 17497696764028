.sprite-router {
    width: 100%;
}
.sprite-router p {
    font-size: calc(.5rem + 1vw);
    padding: 1em 4em;
    line-height: 1.75;
    text-align: left;
}

.sprite-router a {
    color: var(--blue);
    // text-decoration: none;
}

.sprite-router a:hover {
    color: var(--yellow);
}

.sprite-router {
    margin-bottom: 2em;
}

.sprite-router .code {
    color: #D4D4D4;
    overflow-wrap: break-word;
    background-color: #1e1e1e;
}