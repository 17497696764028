.menu {
    width: 100%;
    height: 3em;
    margin: 0;
    padding: 0;
    background-color: #000;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
}

.menu .menuLogo {
    align-self: center;
}

.menu .menuLogo {
    display: inline-block;
    text-align: left;
    width: 24px;
    height: 24px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 50%;
}

.menuItems ul {
    text-transform: uppercase;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: .8rem;
    font-weight: bold;
}

.menuItems ul li {
    float: left;
    padding: 0 1em;
}

.menu a {
    color: #fff;
    text-decoration: none;
}

.menu a:hover {
    color: var(--yellow);
}

.menuLinks {
    margin-left: auto;
}
  
.menuLinks img {
    padding: 8px 8px;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition-duration: .1s;
}

.menuLinks img:hover{
    background-color: rgba(255,255,255,.08);
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,.08);
}

.menuLinks img.discord:hover {
    background-color: rgba(88,101,242,.2);
    box-shadow: 0px 0px 0px 1px rgba(88,101,242,1);
}

.menuLinks img.twitter:hover {
    background-color: rgba(29,155,240,.2);
    box-shadow: 0px 0px 0px 1px rgba(29,155,240,1);
}

.menuLinks img.looksrare:hover {
    background-color: rgba(12,228,102,.2);
    box-shadow: 0px 0px 0px 1px rgba(12,228,102,1);
}

.menuLinks img.opensea:hover {
    background-color: rgba(32,129,226,.2);
    box-shadow: 0px 0px 0px 1px rgba(32,129,226,1);
}

.menuLinks img.etherscan:hover {
    background-color: rgba(191,207,218,.2);
    box-shadow: 0px 0px 0px 1px rgba(191,207,218,1);
}

@media (max-width: 710px) {
    .menu {
        display: none;
    }
}